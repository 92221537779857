import { axios } from "./../plugins/axios";

export function getIp() {
  return axios.request({
    params: {
      module: "app",
      action: "public",
      app: "get_ip"
    }
  });
}

export function postShare(parameter) {
  console.log();
  return axios.request({
    params: {
      module: "app",
      action: "public",
      app: "share",
      ...parameter
    }
  });
}
